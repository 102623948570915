import { Amplify } from "aws-amplify"
import { AuthModeStrategyType } from "aws-amplify/datastore"
import awsExports from "./src/aws-exports"

import "@aws-amplify/ui-react/styles.css"
import "./src/styles/global.css"
import "./src/styles/module.layout.css"
import "aos/dist/aos.css"

Amplify.configure({
  ...awsExports,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
  ssr: true,
  maxRecordsToSync: 30000,
})
