exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-policies-cookies-js": () => import("./../../../src/pages/policies/cookies.js" /* webpackChunkName: "component---src-pages-policies-cookies-js" */),
  "component---src-pages-policies-privacy-js": () => import("./../../../src/pages/policies/privacy.js" /* webpackChunkName: "component---src-pages-policies-privacy-js" */),
  "component---src-pages-policies-terms-js": () => import("./../../../src/pages/policies/terms.js" /* webpackChunkName: "component---src-pages-policies-terms-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-user-account-js": () => import("./../../../src/pages/user/account/[...].js" /* webpackChunkName: "component---src-pages-user-account-js" */),
  "component---src-pages-user-dashboard-js": () => import("./../../../src/pages/user/dashboard.js" /* webpackChunkName: "component---src-pages-user-dashboard-js" */),
  "component---src-pages-user-request-js": () => import("./../../../src/pages/user/request/[...].js" /* webpackChunkName: "component---src-pages-user-request-js" */),
  "component---src-pages-user-requests-js": () => import("./../../../src/pages/user/requests.js" /* webpackChunkName: "component---src-pages-user-requests-js" */)
}

