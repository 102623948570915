/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://2vj78qfa5d.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://n5dieq4enrectewrvrsw55uuva.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-eo5x4maylngsvjhl5nzjsnuboy",
    "aws_cognito_identity_pool_id": "us-east-2:f04c5860-1b9c-464a-a9e9-b8d32c0f4f10",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_RFQfIHYbL",
    "aws_user_pools_web_client_id": "7mlh5ou5277fhq5r7m0ut4irqk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bitcoin-storage-fbac3250174052-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
